import * as React from "react";
import Svg, { Path } from "react-native-svg";

import { IconProps, DISABLED_COLOR } from ".";

export default function FileMagnifyingGlassIcon(props: IconProps) {
  const color = props.disabled ? DISABLED_COLOR : props.color;
  return (
    <Svg width={props.size} height={props.size} viewBox="0 0 48 48" fill="none">
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.5 7.5C8.5 5.84315 9.84314 4.5 11.5 4.5H29.3787C30.1743 4.5 30.9374 4.81607 31.5 5.37868L38.6213 12.5C39.1839 13.0626 39.5 13.8257 39.5 14.6213V22C39.5 22.8284 38.8284 23.5 38 23.5C37.1716 23.5 36.5 22.8284 36.5 22V14.6213L29.3787 7.5L11.5 7.5V40.5H24C24.8284 40.5 25.5 41.1716 25.5 42C25.5 42.8284 24.8284 43.5 24 43.5H11.5C9.84315 43.5 8.5 42.1569 8.5 40.5V7.5Z"
        fill={color || "#181818"}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34 29.5C31.5147 29.5 29.5 31.5147 29.5 34C29.5 36.4853 31.5147 38.5 34 38.5C36.4853 38.5 38.5 36.4853 38.5 34C38.5 31.5147 36.4853 29.5 34 29.5ZM26.5 34C26.5 29.8579 29.8579 26.5 34 26.5C38.1421 26.5 41.5 29.8579 41.5 34C41.5 38.1421 38.1421 41.5 34 41.5C29.8579 41.5 26.5 38.1421 26.5 34Z"
        fill={color || "#181818"}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.9393 37.9393C38.5251 37.3536 39.4749 37.3536 40.0606 37.9393L44.0606 41.9393C44.6464 42.5251 44.6464 43.4749 44.0606 44.0607C43.4749 44.6464 42.5251 44.6464 41.9393 44.0607L37.9393 40.0607C37.3535 39.4749 37.3535 38.5251 37.9393 37.9393Z"
        fill={color || "#181818"}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.5 20C14.5 19.1716 15.1716 18.5 16 18.5H32C32.8284 18.5 33.5 19.1716 33.5 20C33.5 20.8284 32.8284 21.5 32 21.5H16C15.1716 21.5 14.5 20.8284 14.5 20Z"
        fill={color || "#181818"}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.5 26C14.5 25.1716 15.1716 24.5 16 24.5H24C24.8284 24.5 25.5 25.1716 25.5 26C25.5 26.8284 24.8284 27.5 24 27.5H16C15.1716 27.5 14.5 26.8284 14.5 26Z"
        fill={color || "#181818"}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.5 32C14.5 31.1716 15.1716 30.5 16 30.5H22C22.8284 30.5 23.5 31.1716 23.5 32C23.5 32.8284 22.8284 33.5 22 33.5H16C15.1716 33.5 14.5 32.8284 14.5 32Z"
        fill={color || "#181818"}
      />
    </Svg>
  );
}
