import { ApolloProvider } from "@apollo/client";
import { enableMapSet } from "immer";
import Head from "next/head";
import { useCallback } from "react";
import { ToastProvider } from "react-native-toast-notifications";
// Needed to make react-native-draggable-flatlist work. The fix is actually for
// react-native-gesture-handler, which is a dependency of the draggable flatlist.
// https://github.com/necolas/react-native-web/issues/1891#issuecomment-887502705
import "setimmediate";

import { AuthProvider } from "src/auth/contexts/AuthProvider";
import { DefaultLayout } from "src/common/components/DefaultLayout";
import { UserProvider } from "src/common/contexts";
import { QueueStateProvider } from "src/common/contexts/queueState";
import { EnhancedApp } from "src/common/types/next";
import { client } from "src/common/utils/graphql";
// TODO: Possibly remove the global.css once we can leverage a dedicated foundations lib.
import "src/foundations/global.css";
import { FeatureFlagProvider } from "src/foundations/hooks/useFeatureFlag";
import toastOptions from "src/foundations/toastOptions";
import { PaymentTableStateProvider } from "src/payments/contexts/paymentTableState";

// Ref. https://immerjs.github.io/immer/installation/
enableMapSet();

// TODO: Report the ones that come through here to our in-house analytics. So we can compare with
// the data we get from LUX.
// export function reportWebVitals(metric: NextWebVitalsMetric): void {
//   return;
// }

export default function BillingHub({ Component, pageProps }: EnhancedApp): JSX.Element {
  // Use the layout defined at the page level, if available
  const getLayout = Component.getLayout ?? ((page) => <DefaultLayout>{page}</DefaultLayout>);

  const handleOnSignOut = useCallback(() => {
    client.clearStore();
  }, []);

  return (
    <>
      <Head>
        {/* The default title. Override this at page level (optional, but recommended) */}
        <title>Billing Hub</title>
        {/* This is here because of https://nextjs.org/docs/messages/no-document-viewport-meta */}
        <meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width" />
      </Head>

      <AuthProvider onSignOut={handleOnSignOut}>
        <ApolloProvider client={client}>
          <FeatureFlagProvider client={client}>
            <UserProvider>
              <ToastProvider duration={5000} {...toastOptions}>
                <QueueStateProvider>
                  <PaymentTableStateProvider>
                    {getLayout(<Component {...pageProps} />)}
                  </PaymentTableStateProvider>
                </QueueStateProvider>
              </ToastProvider>
            </UserProvider>
          </FeatureFlagProvider>
        </ApolloProvider>
      </AuthProvider>
    </>
  );
}
