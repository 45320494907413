import isSet from "lodash/isSet";
import { useRouter } from "next/router";
import { PropsWithChildren, createContext, useCallback, useEffect, useState } from "react";

import { ColumnSort } from "src/common/types/sorting";
import { noop } from "src/common/utils";
import { decodeUrlData } from "src/common/utils/decodeUrlData";
import { encodeUrlData } from "src/common/utils/encodeUrlData";
import { logError } from "src/common/utils/reporting";
import { PaymentFilters } from "src/payments/types/paymentFilters";

export type UrlFragmentData = {
  filters?: PaymentFilters;
  sort?: ColumnSort[];
  pagination?: {
    after?: string;
    before?: string;
    first: number;
  };
};

export type PaymentTableStateContext = {
  paymentTableState: UrlFragmentData;
  setPaymentTableState: (urlData: UrlFragmentData) => void;
};

const defaultPaymentTableStateContext: PaymentTableStateContext = {
  paymentTableState: {},
  setPaymentTableState: () => noop,
};

export const PaymentTableStateContext = createContext<PaymentTableStateContext>(
  defaultPaymentTableStateContext
);

const filterFieldsOfTypeSet = ["statuses"];

export const PaymentTableStateProvider = (props: PropsWithChildren<unknown>) => {
  const router = useRouter();
  const dataFromUrl = router.asPath.split("#")[1];

  const [paymentTableState, setPaymentTableState] = useState<PaymentTableStateContext>(
    defaultPaymentTableStateContext
  );

  const updateUrlWithPaymentTableState = useCallback(
    async (urlData: UrlFragmentData) => {
      try {
        let transformedFilters = urlData.filters;
        if (urlData && urlData.filters) {
          transformedFilters = Object.fromEntries(
            Object.entries(urlData.filters).map(([key, value]) => {
              if (filterFieldsOfTypeSet.includes(key) && isSet(value)) {
                return [key, [...value]];
              }
              return [key, value];
            })
          );
        }

        const encodedUrlData = await encodeUrlData({
          ...urlData,
          filters: transformedFilters,
        });

        router.replace(`${router.asPath.split("#")[0]}#${encodedUrlData}`);
      } catch (err) {
        logError(err, {
          customMessage: `Unable to encode url data`,
          extraData: { urlData: JSON.stringify({ urlData }) },
        });
      }
    },
    [router]
  );

  const handleSetPaymentTableState = (urlData: UrlFragmentData) => {
    setPaymentTableState({
      ...paymentTableState,
      paymentTableState: urlData,
    });
    updateUrlWithPaymentTableState(urlData);
  };

  const initializePaymentTableStateFromUrl = useCallback(
    async () => {
      if (!router.isReady) return;
      if (dataFromUrl) {
        const data = await decodeUrlData(dataFromUrl);

        if (data === null || data === undefined) {
          router.replace(`${router.asPath.split("#")[0]}`);
        } else {
          //@ts-expect-error will sort later
          if (data.filters) {
            //@ts-expect-error will sort later
            data.filters = Object.fromEntries(
              //@ts-expect-error will sort later
              Object.entries(data.filters).map(([key, value]) => {
                if (filterFieldsOfTypeSet.includes(key)) {
                  //@ts-expect-error will sort later
                  return [key, new Set(value)];
                }
                return [key, value];
              })
            );
          }

          setPaymentTableState({
            ...paymentTableState,
            paymentTableState: data,
          });
        }
      }
    },
    // we only care about this value
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [router.isReady]
  );

  useEffect(() => {
    initializePaymentTableStateFromUrl();
  }, [initializePaymentTableStateFromUrl]);

  return (
    <PaymentTableStateContext.Provider
      value={{ ...paymentTableState, setPaymentTableState: handleSetPaymentTableState }}
    >
      {props.children}
    </PaymentTableStateContext.Provider>
  );
};
